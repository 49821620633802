import * as React from "react"
import Layout from "../components/layout"
import AuthorImage from "../images/my_photo.png"
import { Link } from "gatsby"
import Contentful from "../images/logo_contentful.png"
import Gatsby from "../images/logo_gatsby.png"
import Netlify from "../images/logo_netlify.png"
import Tailwind from "../images/logo_tailwind.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter,faInstagram } from "@fortawesome/free-brands-svg-icons"
import { config } from "@fortawesome/fontawesome-svg-core"

config.autoAddCss = false
 
const About = () => {
    return (
        <Layout>
 <section class="bg-white mb-24">
        <div class="container px-6 pt-10 pb-24  lg:px-24 lg:pt-52 lg:mx-auto">
            <div class="items-center lg:flex">
                <div class="lg:w-1/2">
                    <div class="text-center lg:text-left">
                        <div　class="timing-ease-in-out change-time1 box fadeUp text-sm mb-3 ">Author</div>
                        <h2 class="timing-ease-in-out change-time15 delay-time05 box fadeUp text-5xl text-gray-600 dark:text-gray-100 en mb-8">Ich<span class="hidden">sksfjoa</span><span class="opositt">uori</span> <span class="hidden">58/jijoa</span>Nak<span class="hidden">sklea/ijoa</span><span class="opositt">ruma</span><span class="hidden">sks/85*ds</span>a</h2>
                    </div>



                    <p class="timing-ease-in-out change-time1 delay-time15 box fadeUp mt-4 text-gray-500 dark:text-gray-400 lg:max-w-2xl leading-loose">
                    ヒアリングからニーズを汲み取り、可能なコストカットの提案はもちろん必要に応じて制作をお奨めしないカスタマーファーストな提案も積極的に行います。<br class="hidden lg:block" />またYouTubeをはじめテキストブログやアフィリエイトブログも運営しているため、SEOを考慮したタイトル設定や読みやすい記事のライティング、動画・写真の撮影と編集などデザイン以外のスキルも豊富です。
                    </p>

                    <p class="timing-ease-in-out change-time1 delay-time2 box fadeUp mt-4 text-gray-500 dark:text-gray-400 lg:max-w-2xl leading-loose">
                    Webに関するお仕事のご依頼は現在所属しているWeb制作オフィス<Link class="text-blue-400 underline" to='https://www.ryukyunet.biz/' target="_blank">StyleWeb</Link>にて承らせて頂いております。<br />
                    Webはもちろんその他どんなことでもお気軽にご相談ください。<br />
                    グラフィックやイラストレーションあらゆるデザインそして写真や動画の撮影・編集、さらに看板制作やお庭の掃除に可愛いワンちゃんのお散歩などなんでもこなします。
                    </p>

                    <p class="timing-ease-in-out change-time15 delay-time1 box fadeUp mt-8 text-gray-500 dark:text-gray-400 lg:max-w-2xl leading-loose">
                    <span class="opositt">美学大術<span class="hidden">sks/85*ds</span>芸阪大</span>術学科卒<br />
                    2013年より沖縄へ移住
                    </p>


                    <div class="timing-ease-in-out change-time1 delay-time25 box fadeUp flex items-center mt-6 -mx-2">
                        <Link to='https://twitter.com/1x6' target="_blank" class="text-2xl text-gray-400 mx-4" aria-label="Twitter">
                        <FontAwesomeIcon icon={faTwitter} />
                        </Link>
                        <Link to='https://www.instagram.com/pitiloku/' target="_blank" class="text-2xl text-gray-400 mx-4" aria-label="Instagram">
                        <FontAwesomeIcon icon={faInstagram} />
                        </Link>
                        <Link to='https://www.facebook.com/itiroku' target="_blank" class="text-2xl text-gray-400 mx-4" aria-label="Facebook">
                        <FontAwesomeIcon icon={faFacebookF} />
                        </Link>
                    </div>
                </div>

                <div class="mt-8 lg:mt-0 lg:w-1/2">
                    <div class="flex items-center justify-center lg:justify-end">
                        <div class="max-w-lg shadow-lg">
                        <img src={AuthorImage} alt="author"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container px-6 lg:mx-auto ">
            <section class="text-gray-600 body-font">
                <div class="text-center">
                    <div class="text-sm mb-3">About this SITE</div>
                    <div class="en text-5xl mb-10">Studeo16</div>
                    <p class="mt-4 text-gray-500 dark:text-gray-400 leading-loose text-left lg:text-center">
                        スタジ<span class="opositt">ロチイオ</span>クと読みます。<br />
                        <span class="opositt">ロチイ</span>クという表記にこれといったストーリーはなく、アカウント取得などの際、本名はすでに使われている<br class="hidden lg:block" />
                        場合に似たような憶えやすいのをつける…恐らくみなさんもよくやられているアレです。
                    </p>
                    <p class="mt-4 mb-20 text-gray-500 dark:text-gray-400  leading-loose text-left lg:text-center">
                        美術の課題に自画像が必ずあるようにセルフプロデュースは創作の重要な要素で、また過去の実績以上に語る<br class="hidden lg:block" />
                       言葉のないポートフォリオはデザイナーとして認知してもらえる最重要なツールと考え作成しました。
                    </p>

      <div class="text-gray-800 font-medium title-font tracking-wider text-sm">This website built with:</div>
                </div>
                <div class="container px-5 py-10 max-w-3xl mx-auto">
                    <div class="flex flex-wrap -m-4 text-center">
                         <div class="p-4 sm:w-1/4 w-1/2">
                         <Link to='https://www.gatsbyjs.com/' target="_blank">
                             <div class="flex justify-center mb-1">
                              <img src={Gatsby} alt="gatsby"/>
                             </div>
                             <div class="text-xs">(SSG)</div>
                        </Link>
                        </div>
                        <div class="p-4 sm:w-1/4 w-1/2">
                         <Link to='https://tailwindcss.com/' target="_blank">
                             <div class="flex justify-center mb-1">
                              <img src={Tailwind} alt="tailwind"/>
                             </div>
                             <div class="text-xs">(CSS)</div>
                        </Link>
                        </div>
                        <div class="p-4 sm:w-1/4 w-1/2">
                         <Link to='https://www.netlify.com/' target="_blank">
                             <div class="flex justify-center mb-1">
                              <img src={Netlify} alt="Netlify"/>
                             </div>
                             <div class="text-xs">(hosting)</div>
                        </Link>
                        </div>
                        <div class="p-4 sm:w-1/4 w-1/2">
                         <Link to='https://www.contentful.com/' target="_blank">
                             <div class="flex justify-center mb-1">
                              <img src={Contentful} alt="Contentful"/>
                             </div>
                             <div class="text-xs">(CMS)</div>
                        </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    </section>






          
</Layout>
    )
}

export default About